import React from 'react';
import errorIcon from "../images/icon-error.png";
import iconBack from "../images/icon-left-arrow.png";

function ErrorPage(props) {
    return (
        <div className="full" id="messages">
            <header>
                <span className="back"><a href="/app/messages/"><img src={iconBack} alt="<" width="20" height="20" /></a></span>
                <span className="title">Error</span>
            </header>
            <div id="errorpage">
                <img src={errorIcon} alt="icon" />
                <p>{props.error}</p>
            </div>
        </div>
    );
}

export default ErrorPage;