import React, { Component } from "react";
import {
  Route,
  BrowserRouter,
  Switch,
  Redirect
} from "react-router-dom";
import Messages from "./pages/Messages";
import Chat from "./pages/Chat";
import LoadingIcon from "./components/LoadingIcon"
import './styles.css';
import { auth } from "./services/firebase";
import Cookies from 'universal-cookie';
import { signin } from "./helpers/auth";

class App extends Component {
  constructor() {
    super();
    const cookies = new Cookies();
    this.state = {
      cookies: {
        email: cookies.get('date_email'),
        token: cookies.get('date_token')
      },
      loading: true
    };
  }

  async componentDidMount(){
    if(this.state.cookies.email && this.state.cookies.token){
      try{
          await signin(this.state.cookies.email, this.state.cookies.token);
          this.setState({loading: false});
      } catch (error) {
        this.setState({loading: false});
        console.log(error.message);
      }
    }
  }

  componentWillUnmount() {

  };

  render() {
    if(auth().currentUser){
      return this.state.loading === true ? (
        <LoadingIcon />
      ) : (
        <BrowserRouter>
          <Switch>
            <Route exact path="/app/">
              <Redirect to="/app/messages/" />
            </Route>
            <Route path="/app/messages/" component={Messages} />
            <Route path="/app/chat/" component={Chat} />
          </Switch>
        </BrowserRouter>
      );
    }else{
      return (<LoadingIcon />);
    }
  }
}

export default App;
