import React from 'react';
import {Link} from "react-router-dom";

function ChatList(props) {
    const chats = props.chats;
    const language = props.language;

    return (
        chats.map(function (chat, i){
            const profilePic = 'https://media.dateinvite.com/'+chat.user.photo+'_thumb.jpg';
            return (
              <Link to={{
                pathname: "/app/chat/",
                data: {
                  room: chat.room,
                  user: chat.user,
                  language
                }
              }} key={chat.room}>
                <ul className={(chat.unread>0)?'message new':'message'}>
                  <li className='thumb'><img src={profilePic} alt="{chat.user.name} thumb" width="64" height="64" /></li>
                  <li className='name'>{chat.user.name}</li>
                  <li className='lastmessage'>{chat.lastmessage}</li>
                  {(chat.unread > 0)?<li className='messagesnum'><span>{chat.unread}</span></li>:''}
                </ul>
              </Link>
            )
        })  
    )
}

export default ChatList;