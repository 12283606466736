import { auth } from "../services/firebase";

export function signup(email, password) {
  return auth().createUserWithEmailAndPassword(email, password);
}

export function signin(email, password) {
  return auth().signInWithEmailAndPassword(email, password);
}

export function signInAnonymously() {
  return auth().signInAnonymously();
}

export function signInWithPhoneNumber(phoneNumber) {
  return auth().signInWithPhoneNumber(phoneNumber);
}

export function getaccess(email, password) {
  return auth()
  .signInWithEmailAndPassword(email, password)
  .then(function() {
    console.log('ja tinha login, vamos inserir no BD');
  }).catch(function(error) {
    auth()
    .createUserWithEmailAndPassword(email, password)
    .then(function() {
      console.log('criou cadastro, vamos inserir no BD');
    }).catch(function(error) {
      console.log('erro pra criar');
    });
  });
}

export function logout() {
  return auth().signOut();
}
