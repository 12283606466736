import React, { Component } from "react";
//import { Redirect } from "react-router-dom";
import { db } from "../services/firebase";
import { auth } from "../services/firebase";
import { Link } from 'react-router-dom';
import LoadingIcon from "../components/LoadingIcon"
import ErrorPage from "../components/ErrorPage"
import MsgViewed from "../components/MsgViewed";
import iconSendMsg from "../images/send-icon.png";
import thumbError from "../images/thumb-error.png";
import iconBack from "../images/icon-left-arrow.png";
import iconUserTyping from "../images/icon-user-typing.svg";
import Cookies from 'universal-cookie';
import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

export default class Chat extends Component{
  constructor(props) {
    super(props);
    const chatRoom = (this.props.location.data)?this.props.location.data.room:null;
    const chatUser = (this.props.location.data)?this.props.location.data.user:null;
    const language = (this.props.location.data)?this.props.location.data.language:null;
    this.state = {
      chatRoom,
      chatUser,
      language,
      chatMyUser: auth().currentUser,
      messages: [],
      content: '',
      screenHeight: window.innerHeight,
      loading: true,
      typing: false,
      error: false
    };
    this.cookies = new Cookies();
    this.db_ChatRoom = db.ref('/chats/'+chatRoom);
    this.db_ChatRoomTyping = db.ref('/chats/'+chatRoom+'/info/typing');
    this.db_ChatRoomMessages = this.db_ChatRoom.child('messages');
    this.myRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  async componentDidMount() {
    if(this.props.location.data){
      const chatArea = this.myRef.current;
      try{
        await this.db_ChatRoomMessages.on("value", snapshot => {
          let messages = [];
          let viewedNodes = [];
          snapshot.forEach((snap) => {
            if(snap.val().hide === undefined){
              if(snap.val().from !== this.state.chatMyUser.uid && snap.val().viewed === false){
                viewedNodes.push(snap.key);
              }
              const message = snap.val();
              message['node'] = snap.key;
              messages.push(message);
            }else{
              if(snap.val().hide[this.state.chatMyUser.uid] === undefined){
                if(snap.val().from !== this.state.chatMyUser.uid && snap.val().viewed === false){
                  viewedNodes.push(snap.key);
                }
                const message = snap.val();
                message['node'] = snap.key;
                messages.push(message);
              }
            }
          });
          messages.sort(function (a, b) { return a.timestamp - b.timestamp })
          this.setState({ messages });
          this.updateViewedNodes(viewedNodes);
          chatArea.scrollBy(0, chatArea.scrollHeight);
          this.setState({ loading: false });
        });
      }catch(error){
        this.setState({ error });
      }

      this.db_ChatRoomTyping.on("value", snapshot => {
        snapshot.forEach((snap) => {
          const typing = (snap.key === this.state.chatMyUser.uid)?false:snap.val();
          this.setState({ typing });
          if(typing){chatArea.scrollBy(0, chatArea.scrollHeight);}
        });
      });

    }else{
      this.setState({ error: 'Error in chat data' });
    }
  }

  async componentWillUnmount(){
    if(this.state.chatRoom && this.state.chatMyUser && this.state.chatUser){
      this.db_ChatRoomMessages.off('value');
      this.db_ChatRoomTyping.off('value');
      db.ref('users').child(this.state.chatMyUser.uid).child('notifications').child('chats').child(this.state.chatRoom).remove();
    }
  };

  async handleSubmit(){
    if(this.state.content !== ''){
      const chatArea = this.myRef.current;

      const db_MyUserLastMessage = db.ref('users').child(this.state.chatMyUser.uid).child('chats').child(this.state.chatRoom).child('lastmessage');
      const db_UserLastMessage = db.ref('users').child(this.state.chatUser.id).child('chats').child(this.state.chatRoom).child('lastmessage');
      const db_UserNotification =  db.ref('users').child(this.state.chatUser.id).child('notifications').child('chats').child(this.state.chatRoom);
      let lastmessage = {};

      this.db_ChatRoomMessages.push({
        content: this.state.content,
        timestamp: Date.now(),
        from: this.state.chatMyUser.uid,
        viewed: false
      })
      .then((snap) => {

        lastmessage = {
          message: snap.key,
          content: this.state.content,
          timestamp: Date.now()
        }
        
        db_MyUserLastMessage.update(lastmessage);
        db_UserLastMessage.update(lastmessage);
        db_UserNotification.child(snap.key).update(lastmessage);

        this.setState({content: ''});
        chatArea.scrollBy(0, chatArea.scrollHeight);
      })
      .catch(error => {
        this.setState({error: error.code});
      });

      //ENVIAR NOTIFICAÇÃO API
      this.apiSendNotification(
        {
          type: 'message',
          user: this.state.chatUser.id,
          content: this.state.content,
          timestamp: Date.now()
        }
      );
    }
  }

  
  handleChange(event){
    const myUserTyping = {};
    myUserTyping[this.state.chatMyUser.uid] = true;
    this.db_ChatRoom.child('info').child('typing').set(myUserTyping)
    this.setState({content: event.target.value});
  }

  handleBlur(event){
    const myUserTyping = {};
    myUserTyping[this.state.chatMyUser.uid] = false;
    this.db_ChatRoom.child('info').child('typing').set(myUserTyping);
  }

  render(){
    if(this.state.error){
      return (<ErrorPage error={this.state.error} />);
    }else{
      const profilePic = (this.state.chatUser)?'https://media.dateinvite.com/'+this.state.chatUser.photo+'_thumb.jpg':thumbError;
      const profileName = (this.state.chatUser)?this.state.chatUser.name:'';
      return (
        <div className="full" id="chat">
          <header>
            <span className="back"><Link to="/app/messages/"><img src={iconBack} alt="<" width="20" height="20" /></Link></span>
            <span className="thumb"><img src={profilePic} alt="Thumbnail" width="36" height="36" /></span>
            <span className="title">{profileName}</span>
          </header>

          <SwipeableList>
            {({ className, ...rest }) => (
              <div className={className} ref={this.myRef}>
                {this.state.loading ? <LoadingIcon /> : ""}
                {this.state.messages.map(message => {
                  const viewed = (message.from === this.state.chatMyUser.uid ? <MsgViewed viewed={message.viewed} /> : "");
                  return (
                    <SwipeableListItem
                      key={message.node}
                      swipeLeft={{
                        content: <div className="delete">X</div>,
                        action: () => this.deleteMessage(message.node, message.from)
                      }}
                    >
                      <div className={message.from === this.state.chatMyUser.uid ? 'message current' : 'message'}>
                        <div dangerouslySetInnerHTML={{__html: message.content.replace(/\n/g, '<br>')}} />
                        <div className="information">
                          {this.formatTime(message.timestamp)}
                          {viewed}
                        </div>
                      </div>
                    </SwipeableListItem>     
                  )
                })}
                {this.state.typing ? <p className="usertyping"><img src={iconUserTyping} alt='typing' width='32' /></p>: ''}
            </div>
            )}
          </SwipeableList>

          <div className="send-form" style={{position: this.state.headerPosition}}>
            <textarea
              className="message"
              dir="ltr"
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={this.state.content}
            />
            <img className="btn-send" onClick={this.handleSubmit} src={iconSendMsg} alt="Send" width="45" />
          </div>
        </div>
      );
    }
  }

  async updateViewedNodes(nodes){
    const updateNodes = {};
    nodes.forEach(node => {
      updateNodes[node+'/viewed'] = true;
    });
    try{
      this.db_ChatRoomMessages.update(updateNodes);
    }catch(error){
      console.log(error);
    }
  }

  formatTime(timestamp){
    const lang = this.state.language;
    const date = new Date(timestamp);
    let day = date.getDate();
    day = ("0" + day).slice(-2);
    let month = date.getMonth()+1;
    month = ("0" + month).slice(-2);
    let year = date.getFullYear();
    let hours = date.getHours();
    hours = ("0" + hours).slice(-2);
    let minutes = date.getMinutes();
    minutes = ("0" + minutes).slice(-2);
    let ampm = '';

    if(lang === 'en'){
      if(hours === '12'){
        ampm = ' PM';
      }else if(hours === '00'){
        hours = '12';
        ampm = ' AM';
      }else if(hours>12){
        hours = ('0' + (hours-12)).slice(-2);
        ampm = ' PM';
      }else{
        ampm = ' AM';
      }
    }

    const time = (lang === 'en')?`${month}/${day}/${year} ${hours}:${minutes}${ampm}`:`${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
    return time;
  }

  deleteMessage(id, from){
    const myId = this.state.chatMyUser.uid;
    let hide = {};
    hide[myId] = true;

    const db_MyLastMessage = db.ref('users')
    .child(this.state.chatMyUser.uid)
    .child('chats')
    .child(this.state.chatRoom)
    .child('lastmessage');

    const db_UserLastMessage = db.ref('users')
    .child(this.state.chatUser.id)
    .child('chats')
    .child(this.state.chatRoom)
    .child('lastmessage');

    const lang = this.state.language;
    let txt_lang = {};
    
    if(lang === 'sp'){
      txt_lang = {
        message: '¿Borrar esta mensaje?',
        for_me: 'Para mi',
        for_us: 'Para todos',
        cancel: 'Cancelar'
      };
    }else if(lang === 'pt'){
      txt_lang = {
        message: 'Apagar a mensagem?',
        for_me: 'Para mim',
        for_us: 'Para todos',
        cancel: 'Cancelar'
      };
    }else{
      txt_lang = {
        message: 'Delete this message?',
        for_me: 'For me',
        for_us: 'For everyone',
        cancel: 'Cancel'
      };
    }

    if(myId === from){
      confirmAlert({
        message: `${txt_lang.message}`,
        buttons: [
          {
            label: `${txt_lang.for_me}`,
            onClick: () => {
              this.db_ChatRoomMessages.child(id).child('hide').update(hide);
              db_MyLastMessage.child('message').once('value', snapshot => {
                if(snapshot.val() === id){
                  db_MyLastMessage.remove();
                }
              });
            }
          },
          {
            label: `${txt_lang.for_us}`,
            onClick: () => {
              this.db_ChatRoomMessages.child(id).remove();
              db_MyLastMessage.child('message').once('value', snapshot => {
                if(snapshot.val() === id){
                  db_MyLastMessage.remove();
                }
              });

              db_UserLastMessage.child('message').once('value', snapshot => {
                if(snapshot.val() === id){
                  db_UserLastMessage.remove();
                  db.ref('users').child(this.state.chatUser.id).child('notifications').child('chats').child(this.state.chatRoom).child(id).remove();
                }
              });
              
            }
          },
          {
            label: `${txt_lang.cancel}`,
            onClick: () => undefined
          }
        ]
      });
    }else{
      confirmAlert({
        message: `${txt_lang.message}`,
        buttons: [
          {
            label: `${txt_lang.for_me}`,
            onClick: () => {
              this.db_ChatRoomMessages.child(id).child('hide').update(hide);
              db_MyLastMessage.child('message').once('value', snapshot => {
                if(snapshot.val() === id){
                  db_MyLastMessage.remove();
                }
              });
            }
          },
          {
            label: `${txt_lang.cancel}`,
            onClick: () => undefined
          }
        ]
      });
    }

  }

  async apiSendNotification(data){
    fetch('https://api.dateinvite.com/users/send_notification.json',{
      'method':'POST',
      'headers':{
        'Authorization': 'Basic '+btoa(this.cookies.get('date_email') + ':' + this.cookies.get('date_token'))
      },
      'body': JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
      //console.log(data);
    })
    .catch(function(error) {
        console.log('Looks like there was a problem: \n', error);
    });
  }

}
