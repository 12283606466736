import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
const config = {
  apiKey: "AIzaSyBRj6b2RrKFoTwxFqxjKI1RC5jlo5IvTrc",
  authDomain: "dateinvite-9f2b1.firebaseapp.com",
  databaseURL: "https://dateinvite-9f2b1.firebaseio.com/"
};

firebase.initializeApp(config);

export const auth = firebase.auth;
export const db = firebase.database();
