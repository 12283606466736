import React from 'react';
import img_loading from '../images/loading.gif';

function LoadingIcon() {
    return (
        <div id="loadingicon" role="status">
            <img src={img_loading} width="48" alt="Loading..." />
        </div>    
    )
}

export default LoadingIcon;