import React, { Component } from "react";

import { db, auth } from "../services/firebase";
import LoadingIcon from "../components/LoadingIcon"
import ErrorPage from "../components/ErrorPage"
import iconBack from "../images/icon-left-arrow.png";
import Cookies from 'universal-cookie';
import ChatList from "../components/ChatList";

export default class Messages extends Component {
  constructor(props){
    super(props);
    const cookies = new Cookies();
    this.state = {
      chats: false,
      myUser: auth().currentUser,
      loading: true,
      lastmessages: false,
      notifications: false,
      language: false,
      title: false,
      cookies: {
        email: cookies.get('date_email'),
        token: cookies.get('date_token')
      },
      error: false
    };
  }

  async componentDidMount(){
    this.apiMessagesChats();
    
    //PEGANDO AS ULTIMAS MENSAGENS
    this.db_Chats = db.ref('users').child(this.state.myUser.uid).child('chats');
    this.db_Chats.on('value', snapshot => {
      let lastmessages = {};
      snapshot.forEach((snap) => {
        lastmessages[snap.key] = snap.val().lastmessage;
      });
      this.setState({lastmessages});
    });
    
    //PEGAR NOTIFICAÇÕES
    this.db_Notifications = db.ref('users').child(this.state.myUser.uid).child('notifications').child('chats');
    this.db_Notifications.on("value", snapshot => {
      let notifications = {};
      snapshot.forEach((snap) => {
        notifications[snap.key] = Object.keys(snap.val()).length;
      });
      this.setState({notifications});
    });
  }
  
  componentWillUnmount() {
    this.db_Chats.off('value');
    this.db_Notifications.off('value');
    this.mounted = false;
  };

  render() {
    if(this.state.error){
      return (<ErrorPage error={this.state.error} />);
    }else{
      const chats = this.state.chats;
      const lastmessages = this.state.lastmessages;
      const notifications = this.state.notifications;
      let orderChats = false;

      if(chats && lastmessages && notifications){
        chats.forEach((chat, i) => {
            chats[i]['unread'] = (notifications[chat.room])?notifications[chat.room]:'';
            chats[i]['lastmessage'] = (lastmessages[chat.room])?lastmessages[chat.room].content:'';
            chats[i]['timestamp'] = (lastmessages[chat.room])?lastmessages[chat.room].timestamp:'';
        });
        orderChats = chats.sort(this.orderChats);
      }

      return (
        <div className="full" id="messages">
          <header>
              <span className="back"><a href="/users.php"><img src={iconBack} alt="<" width="20" height="20" /></a></span>
              <span className="title">{this.state.title}</span>
          </header>

          <div className="chats">
            { orderChats ? <ChatList chats={orderChats} language={this.state.language} /> : <LoadingIcon /> }
          </div>
        </div>
      );
    }
  }

  async apiMessagesChats(){
    this.mounted = true;
    await fetch('https://api.dateinvite.com/messages/chats.json',{
      'method':'POST',
      'headers':{
        'Authorization': 'Basic '+btoa(this.state.cookies.email + ':' + this.state.cookies.token)
      }
    })
    .then(response => response.json())
    .then(data => {
      if(data.response==='1'){
        const chats = data.messages;
        if(chats){
          this.setState({
            chats: data.messages,
            language: data.lang,
            title: data.header.text,
            loading: false
          });
        }else{
          this.setState({ error: 'No chats' });
        }
      }else{
        this.setState({ error: 'No response from server' });
      }
    })
    .catch(function(error) {
      this.setState({ error: 'Fetch chats error' });
    });
  }

  orderChats(a, b){
    const roomA = (a.timestamp === '')?0:a.timestamp;
    const roomB = (b.timestamp === '')?0:b.timestamp;
    let comparison = 0;
    if(roomA > roomB){
      comparison = 1;
    }else if(roomA < roomB){
      comparison = -1;
    }
    return comparison * -1;
  }

}